import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'formik';
import classnames from 'classnames';

import './StyledForm.css';

//
// Just a styled Formik Form
//
const StyledForm = ({ children, ...reset }) =>
  // TODO: Need to track down 'eap-gray-form'
  // and bring that over here or something...
  (
    <div className="styled-form-container">
      <Form className={classnames([
        'styled-input-form',
        'pure-form', 'pure-form-stacked', 'eap-grey-form'
        ])}
        {...reset}
      >
        {children}
      </Form>
    </div>
  );

StyledForm.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
};
export default StyledForm;
