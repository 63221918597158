/* eslint jsx-a11y/label-has-associated-control: 0 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import 'react-datepicker/dist/react-datepicker.css';
import classnames from 'classnames';

import { scrubUserData } from '../../utils/FormsHelper';
import InlineValidationMessage from '../../components/InlineValidationMessage';
import FormInputGroup from '../../components/FormInputGroup';
import EapDatePicker from '../../components/EapDatePicker';
import GradeLevelSelectList from '../../components/GradeLevelSelectList';
import GenderSelectList from '../../components/GenderSelectList';
import {
  CoreUserProfileSchema,
  RequiredEmailSchema,
  UniqueEmailSchema,
  UsernameSchema,
  NewPasswordSchema,
  ConfirmPasswordSchema,
  TermsAndConditionsSchema,
  UniqueUsernameSchema,
} from './UserSchema';
import EapButton from '../../components/EapButton';

import './PublicSignupForm.css';

const PublicSignupForm = ({
  onSubmit,
  redirectUrl,
  loading,
}) => {
  const [usernameOnly, setUsernameOnly] = useState(false);
  const formik = useFormik({
    initialValues: {
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
      dob: '',
      gender: '',
      grade: '',
      termsAndConditions: false
    },
    validationSchema: CoreUserProfileSchema
      .concat(
        yup.object().shape({
          email: usernameOnly ? undefined : RequiredEmailSchema.concat(UniqueEmailSchema()),
          username: usernameOnly
            ? UsernameSchema.concat(UniqueUsernameSchema())
            : undefined,
          password: NewPasswordSchema,
          confirmPassword: ConfirmPasswordSchema,
          termsAndConditions: TermsAndConditionsSchema,
        })
      ),
    onSubmit: (values) => {
      if (loading) {
        return;
      }
      const scrubbed = scrubUserData(values);
      onSubmit(
        {
          ...scrubbed,
          username: usernameOnly ? scrubbed.username : scrubbed.email,
        },
        redirectUrl
      );
    },
  });

  return (
    <div className="styled-form-container">
      <form
        className={classnames([
          'styled-input-form',
          'pure-form',
          'pure-form-stacked',
          'eap-grey-form',
        ])}
        onSubmit={formik.handleSubmit}
      >
        <div className="public-email-username-form-group">
          <label
            htmlFor={usernameOnly ? 'username' : 'email'}
          >
            {usernameOnly ? 'Username *' : 'Email *'}
          </label>
          <button
            type="button"
            className="email-username-toggle"
            onClick={() => {
              if (!usernameOnly) {
                formik.setFieldValue('email', '');
              } else {
                formik.setFieldValue('username', '');
              }
              setUsernameOnly(!usernameOnly);
            }}
          >
            {
              usernameOnly
                ? 'I have an email address'
                : 'I have no email and would like to choose my own username.'
            }
          </button>
          <input
            name={usernameOnly ? 'username' : 'email'}
            type="text"
            {...formik.getFieldProps(usernameOnly ? 'username' : 'email')}
          />
          {
            usernameOnly
              && formik.touched.username
              && formik.errors.username
              && <InlineValidationMessage message={formik.errors.username} />
          }
          {
            !usernameOnly
              && formik.touched.email
              && formik.errors.email
              && <InlineValidationMessage message={formik.errors.email} />
          }
        </div>
        <FormInputGroup required>
          <label htmlFor="password">PASSWORD</label>
          <input
            type="password"
            name="password"
            {...formik.getFieldProps('password')}
          />
          {
            formik.touched.password
              && formik.errors.password
              && <InlineValidationMessage message={formik.errors.password} />
          }
        </FormInputGroup>
        <FormInputGroup required>
          <label htmlFor="confirmPassword">CONFIRM PASSWORD</label>
          <input
            type="password"
            name="confirmPassword"
            {...formik.getFieldProps('confirmPassword')}
          />
          {
            formik.touched.confirmPassword
              && formik.errors.confirmPassword
              && <InlineValidationMessage message={formik.errors.confirmPassword} />
          }
        </FormInputGroup>
        <FormInputGroup required>
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            name="firstName"
            {...formik.getFieldProps('firstName')}
          />
          {
            formik.touched.firstName
              && formik.errors.firstName
              && <InlineValidationMessage message={formik.errors.firstName} />
          }
        </FormInputGroup>
        <FormInputGroup required>
          <label htmlFor="lastName">Last Name</label>
          <input
            type="text"
            name="lastName"
            {...formik.getFieldProps('lastName')}
          />
          {
            formik.touched.lastName
              && formik.errors.lastName
              && <InlineValidationMessage message={formik.errors.lastName} />
          }
        </FormInputGroup>
        <FormInputGroup>
          <label htmlFor="dob">
            DATE OF BIRTH (MM/DD/YYYY)
          </label>
          <EapDatePicker
            initialDate={formik.values.dob}
            onDateSelected={(dateVal) => formik.setFieldValue('dob', dateVal)}
          />
        </FormInputGroup>
        <FormInputGroup>
          <label htmlFor="gender">
            GENDER
          </label>
          <GenderSelectList
            noField
            {...formik.getFieldProps('gender')}
          />
        </FormInputGroup>
        <FormInputGroup>
          <label htmlFor="grade">GRADE LEVEL</label>
          <GradeLevelSelectList
            noField
            {...formik.getFieldProps('grade')}
          />
        </FormInputGroup>
        <FormInputGroup>
          <label
            id="public-terms-and-conditions"
            htmlFor="termsAndConditions"
          >
            <input
              type="checkbox"
              name="termsAndConditions"
              className="eap-form-checkbox"
              {...formik.getFieldProps('termsAndConditions')}
            />
            I agree to&nbsp;
            <a href="https://www.edits.net/legal/terms-of-use/" target="_blank" rel="noopener noreferrer">
              terms and conditions
            </a>
            &nbsp;for this site <span className="required">*</span>
          </label>
          {
            formik.touched.termsAndConditions
              && formik.errors.termsAndConditions
              && <InlineValidationMessage message={formik.errors.termsAndConditions} />
          }
        </FormInputGroup>
        <FormInputGroup>
          <EapButton
            isSubmit
            text="SIGN UP"
            inProgress={loading || formik.isValidating}
            disabled={loading || formik.isValidating}
          />
        </FormInputGroup>
      </form>
    </div>
  );
};

PublicSignupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  redirectUrl: PropTypes.string
};

PublicSignupForm.defaultProps = {
  redirectUrl: undefined
};

export default PublicSignupForm;
