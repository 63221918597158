import React from 'react';
import { Field } from 'formik';

const GenderSelectList = ({ noField, ...rest }) => {
  if (noField) {
    return (
      <select
        name="gender"
        {...rest}
      >
        <option value="" />
        <option value="M">Male</option>
        <option value="F">Female</option>
      </select>
    );
  }

  return (
    <Field
      component="select"
      name="gender"
      {...rest}
    >
      <option value="" />
      <option value="M">Male</option>
      <option value="F">Female</option>
    </Field>
  );
};

export default GenderSelectList;
