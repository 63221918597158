import { connect } from 'react-redux';
import { signUpNewUser } from '../actions/signup';
import Signup from './components/Signup';

const mapStateToProps = (state) => ({
  loading: state.signup.loading,
  signupErr: state.signup.signupErr,
  signupSuccess: state.signup.signupSuccess
});

const mapDispatchToProps = (dispatch) => ({
  signUpNewUser: (userInfo, redirectUrl) => {
    dispatch(signUpNewUser(userInfo, redirectUrl));
  }
});

const SignupContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Signup);

export default SignupContainer;
